// custom colors for Logitech theme
//$blue                   :  #1194f6;
$blue                   :  #00bfff; //azzurro
$light-blue             :  #0ab9ff; //gaming blue
$teal                   :  #00ead0;	//ciano
//$pink                   :  #ff7075;
$pink                   :  #ff6861;  //neo
$yellow                 :  #dbfd01;
$yellow-green           :  #dcfd00; //electrico
//$yellow-green           :  #dcfd00;
$purple                 :  #814efa; //lila
//$red                    :  #f93248;
$red                    :  #ff2947; //kirsche
//$grey                   :  #c2c6ca;
$grey                   :  #c3c6c8;	//acier
$text-gray              :  #2f3132; //kohle
$light-gray             :  #ededed;
$gray                   :  #aaaaaa;
$dark-gray              :  #252a2c;
$medium-gray            :  #555555;
$inbetween-gray         :  #777777;
$medium-dark-gray       :  #888888;
$dark-gray-line         :  #212628;
$white                  :  #ffffff;
$black                  :  #000000;

//$medium-blue            :  #0ab9ff;
//$gaming-gray            :  #101820;


$faded				:rgba(#000, .4);

$WhiteSixtySixPercent   :  rgba(255, 255, 255, 0.66);
$whiteTwentyPercent     :  rgba(255, 255, 255, 0.2);

$hover-delay            :  .33s;
$sprite-sheet-checkbox  :  url('../../images/checkbox_sprite_sheet.png');
