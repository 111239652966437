//colors
$color_mine_shaft_approx: #000;
$black: #000;
$white: #fff;

//fonts
$font_0: arial;
$font_1: helvetica;
$font_2: sans-serif;

//@extend-elements
//original selectors
//html, body
%extend_1 {
	margin: 0;
	padding: 0;
}

//original selectors
//#pp-nav li, .pp-slidesNav li
%extend_2 {
	display: block;
	width: 14px;
	height: 13px;
	margin: 7px;
	position: relative;
}

//original selectors
//#pp-nav li a, .pp-slidesNav li a
%extend_3 {
	display: block;
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	cursor: pointer;
	text-decoration: none;
}

//original selectors
//#pp-nav span, .pp-slidesNav span
%extend_4 {
	top: 2px;
	left: 2px;
	width: 8px;
	height: 8px;
	border: 1px solid $black;
	background: rgba(0, 0, 0, 0);
	//Instead of the line below you could use @includeborder-radius($radius, $vertical-radius)
	border-radius: 50%;
	position: absolute;
	z-index: 1;
}


html.home {
	@extend %extend_1;
}
body.home {
	@extend %extend_1;
}
.pp-section {
	height: 100%;
	position: absolute;
	width: 100%;
	&.pp-table {
		display: table;
	}
}
.pp-easing {
	//Instead of the line below you could use @includetransition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: all 1000ms cubic-bezier(0.550, 0.085, 0.000, 0.990);
	//Instead of the line below you could use @includetransition-timing-function($function-1, $function-2, $function-3, $function-4, $function-5, $function-6, $function-7, $function-8, $function-9, $function-10)
	transition-timing-function: cubic-bezier(0.550, 0.085, 0.000, 0.990);
}
#pp-nav {
	position: fixed;
	z-index: 100;
	margin-top: -32px;
	top: 50%;
	opacity: 1;
	&.right {
		right: 17px;
	}
	&.left {
		left: 17px;
	}
	ul {
		margin: 0;
		padding: 0;
	}
	li {
		@extend %extend_2;
		a {
			@extend %extend_3;
		}
		.active span {
			border-color: $yellow-green !important;
			background: $yellow-green;
		}
	}
	span {
		@extend %extend_4;
	}

	&.hide {
		display: none;
	}
}
.pp-tableCell {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	height: 100%;
}
.pp-slidesNav {
	position: absolute;
	z-index: 4;
	left: 50%;
	opacity: 1;
	&.bottom {
		bottom: 17px;
	}
	&.top {
		top: 17px;
	}
	ul {
		margin: 0;
		padding: 0;
	}
	li {
		@extend %extend_2;
		display: inline-block;
		a {
			@extend %extend_3;
		}
	}
	span {
		@extend %extend_4;
	}
	.active span {
		background: $yellow;
	}
}
.pp-tooltip {
	position: absolute;
	top: -2px;
	color: $white;
	font-size: 14px;
	font-family: $font_0, $font_1, $font_2;
	white-space: nowrap;
	max-width: 220px;
	&.right {
		right: 20px;
	}
	&.left {
		left: 20px;
	}
}
.pp-scrollable {
	overflow-y: scroll;
	height: 100%;
}
